import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import { Logo } from "../../../theme/Logo";
import { LogoDark } from "../../../theme/LogoDark";
import { HamburgerIcon } from "../../../theme/HamburgerIcon";
import { HamburgerIconDark } from "../../../theme/HamburgerIconDark";
import { CloseIcon } from "../../../theme/CloseIcon";
import HeaderNav from "../HeaderNav";
import Headroom from "react-headroom";
import { SocialLinks } from "../SocialLinks";
import "./Header.scss";
import loadable from "@loadable/component";
import GatsbyLink from "../GatsbyLink";

const isClient = typeof window !== "undefined";

class Header extends Component {
  state = {
    contactActive: false,
    navActive: false,
    hoverClear: false,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (window.innerWidth > 1080) {
      if (isClient) {
        let childrenDomElements = document.querySelectorAll(".children");
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains("open")) {
            childrenDomElements[i].classList.remove("open");
          }
        }
        if (document.body.classList.contains("nav-open")) {
          this.toggleNav();
        }
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toggleNav = (event) => {
    const { navActive } = this.state;
    if (event) event.preventDefault();
    if (!navActive) {
      if (isClient) {
        document.body.classList.add("nav-open");
      }
      this.setState({
        contactActive: false,
        navActive: true,
      });
    } else {
      if (isClient) {
        document.body.classList.remove("nav-open");
      }
      this.setState({ navActive: false });
    }
  };

  closeAll = (event) => {
    if (event) event.preventDefault();
    if (isClient) {
      document.body.classList.remove("nav-open");
    }
    this.setState({
      navActive: false,
    });
  };

  resetHoverClear = (event, value) => {
    this.setState({ hoverClear: true, navActive: false });
    if (isClient) {
      document.body.classList.remove("nav-open");
    }
    setTimeout(() => {
      this.setState({ hoverClear: false });
    }, 1000);
  };

  render() {
    const { navActive, hoverClear } = this.state;
    const { data, theme, location } = this.props;
    const {
      abovePrimaryNavigation,
      primaryNavigation,
      siteSettings,
      metaData,
      wordpressWpSettings,
    } = data;
    const { options } = siteSettings;
    // const { quote, showQuoteButtonInHeader, quotePage } = options;
    const { phone } = options.locations[0];
    const { baseUrl } = wordpressWpSettings;
    const { items: navItems } = primaryNavigation;
    const { items: aboveNavItems } = abovePrimaryNavigation;

    return (
      <Headroom disableInlineStyles={true} pinStart={50}>
        <header className={options.invertHeaderColours ? "invert" : ""}>
          {options.navigationAbovePrimaryMenu && (
            <div className="above-header">
              <div className="inner">
                {options.contactNumberHeader && (
                  <div className="phone">
                    <a
                      href={`tel:${phone.replace(/[^A-Z0-9]/gi, "")}`}
                      className="control-call"
                      aria-label="Call Now"
                    >
                      {phone}
                    </a>
                  </div>
                )}
                {options.socialLinksHeader && <SocialLinks />}
                <HeaderNav
                  active={navActive}
                  className="above-head-navigation"
                  resetHoverClear={this.resetHoverClear}
                  hoverClear={hoverClear}
                  navItems={aboveNavItems}
                  baseUrl={baseUrl}
                  toggleNav={this.toggleNav}
                />
              </div>
            </div>
          )}
          <div
            className={`header-main${
              location.pathname === "/" ? " transparent" : ""
            } ${location.pathname === "/" && !navActive ? "homepage": ""} `}
          >
            <div className="wrap">
              <div className="inner">
                <Logo />
                <div className={`nav${navActive ? " active" : ""}`}>
                  <nav className={`nav-inner${navActive ? " active" : ""}`}>
                    {options.navigationAbovePrimaryMenu && (
                      <HeaderNav
                        active={navActive}
                        className="above-head-navigation-mobile"
                        resetHoverClear={this.resetHoverClear}
                        hoverClear={hoverClear}
                        navItems={aboveNavItems}
                        baseUrl={baseUrl}
                        toggleNav={this.toggleNav}
                      />
                    )}
                    <HeaderNav
                      active={navActive}
                      className="navigation"
                      navItems={navItems}
                      resetHoverClear={this.resetHoverClear}
                      hoverClear={hoverClear}
                      baseUrl={baseUrl}
                      toggleNav={this.toggleNav}
                    />
                  </nav>
                  <div className="controls">
                    {options.showSearchIconInMainNavigation && (
                      <GatsbyLink
                        className={`control-search icon-search`}
                        to="/search/"
                        aria-label="Toggle Search"
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.7104 20.2899L18.0004 16.6099C19.4405 14.8143 20.1379 12.5352 19.9492 10.2412C19.7605 7.94721 18.7001 5.81269 16.9859 4.27655C15.2718 2.74041 13.0342 1.91941 10.7333 1.98237C8.43243 2.04534 6.24311 2.98747 4.61553 4.61505C2.98795 6.24263 2.04582 8.43194 1.98286 10.7328C1.9199 13.0337 2.7409 15.2713 4.27704 16.9854C5.81318 18.6996 7.94769 19.76 10.2417 19.9487C12.5357 20.1374 14.8148 19.44 16.6104 17.9999L20.2904 21.6799C20.3834 21.7736 20.494 21.848 20.6158 21.8988C20.7377 21.9496 20.8684 21.9757 21.0004 21.9757C21.1324 21.9757 21.2631 21.9496 21.385 21.8988C21.5068 21.848 21.6174 21.7736 21.7104 21.6799C21.8906 21.4934 21.9914 21.2442 21.9914 20.9849C21.9914 20.7256 21.8906 20.4764 21.7104 20.2899ZM11.0004 17.9999C9.61592 17.9999 8.26255 17.5894 7.1114 16.8202C5.96026 16.051 5.06305 14.9578 4.53324 13.6787C4.00342 12.3996 3.8648 10.9921 4.1349 9.63427C4.40499 8.27641 5.07168 7.02912 6.05065 6.05016C7.02961 5.07119 8.27689 4.4045 9.63476 4.13441C10.9926 3.86431 12.4001 4.00293 13.6792 4.53275C14.9583 5.06256 16.0515 5.95977 16.8207 7.11091C17.5899 8.26206 18.0004 9.61544 18.0004 10.9999C18.0004 12.8564 17.2629 14.6369 15.9501 15.9497C14.6374 17.2624 12.8569 17.9999 11.0004 17.9999Z" fill="currentColor"/>
                        </svg>
                      </GatsbyLink>
                    )}
                    <button
                      type="button"
                      className={`control-nav icon-hamburger ${
                        navActive ? "active" : ""
                      }`}
                      onClick={this.toggleNav}
                      aria-label="Toggle Menu"
                    >
                      {navActive ? (
                        <CloseIcon />
                      ) : location.pathname === "/" ? (
                        <HamburgerIcon />
                      ) : (
                        <HamburgerIconDark />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Headroom>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        abovePrimaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "above-primary-navigation" }
        ) {
          items {
            title
            url
            classes
            object_id
            slug: object_slug
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        primaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "primary-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            navigationAbovePrimaryMenu
            socialLinksHeader
            contactNumberHeader
            invertHeaderColours
            showSearchIconInMainNavigation
            locations {
              addressDisplay
              email
              phone
            }
            quote
            quotePage
            showQuoteButtonInHeader
            quotePage
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={(data) => <Header data={data} {...props} />}
  />
);
