import React from "react";
import GatsbyLink from "../src/components/GatsbyLink";
import "./Logo.scss";

export const Logo = ({ theme }) => {
  return (
    <GatsbyLink to="/" className="logo">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 634.46 138.35">
        <defs>
          <linearGradient
            id="d"
            x1="68.11"
            y1="-143.4"
            x2="203.94"
            y2="-143.4"
            gradientTransform="translate(-68.11 -74.24) scale(1 -1)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#27b34b" />
            <stop offset=".61" stopColor="#9aca3c" />
          </linearGradient>
          <linearGradient
            id="e"
            x1="144.5"
            y1="-212.59"
            x2="144.5"
            y2="-147.16"
            gradientTransform="translate(-68.11 -74.24) scale(1 -1)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#3ebce9" />
            <stop offset=".22" stopColor="#42bde1" />
            <stop offset=".57" stopColor="#4ebfca" />
            <stop offset="1" stopColor="#61c3a5" />
            <stop offset="1" stopColor="#61c3a5" />
          </linearGradient>
        </defs>
        <g id="a" />
        <g id="b">
          <g id="c">
            <path
              className="g"
              fill="url(#d)"
              d="M56.69,0C25.39,.03,.03,25.39,0,56.69v81.63H8.89l.53-.88c1.73-2.92,3.7-6,5.82-9.28s4.45-6.65,7-10.08c2-2.82,4.2-5.65,6.44-8.51l1.45-1.96c2.83-3.47,5.75-7,8.82-10.32l2.3-2.53c.78-.83,1.57-1.64,2.36-2.46s1.56-1.65,2.37-2.45l2.41-2.36c3.2-3.15,6.49-6.12,9.76-8.91s6.53-5.35,9.72-7.68c1.56-1.22,3.17-2.25,4.7-3.32,.77-.52,1.51-1.06,2.27-1.55l2.23-1.4c2.9-1.9,5.69-3.4,8.14-4.82s4.69-2.42,6.51-3.33l5.83-2.63-6.16,1.79c-1.93,.65-4.29,1.45-7,2.42s-5.69,2.19-8.88,3.69l-2.47,1.1c-.84,.38-1.67,.82-2.52,1.24-1.7,.87-3.5,1.7-5.26,2.7-3.58,1.92-7.29,4.08-11,6.46s-7.57,5-11.33,7.73l-2.84,2.1c-.94,.7-1.86,1.44-2.79,2.17s-1.88,1.46-2.8,2.21l-2.75,2.28c-1.09,.9-2.17,1.82-3.24,2.74V55.36c.02-14.69,11.92-26.59,26.61-26.61h52.24v21.86h28.47V0H56.69Z"
            />
            <path
              className="f"
              fill="url(#e)"
              d="M107.33,72.95v10c.01,14.71-11.9,26.64-26.61,26.66H40.49c1-1.32,2-2.62,3-3.85,1.35-1.64,2.71-3.2,4.05-4.63,.65-.73,1.33-1.39,2-2.05l1-1,1-.89c1.23-1.2,2.43-2.21,3.49-3.13s2-1.63,2.82-2.26l2.56-1.85-2.75,1.58c-.84,.54-1.89,1.19-3.06,2s-2.46,1.7-3.83,2.78l-1.06,.8-1.07,.88c-.72,.59-1.48,1.2-2.22,1.87-1.5,1.31-3,2.74-4.59,4.28s-3.12,3.16-4.64,4.87c-.18,.19-.35,.4-.53,.6-.58,.66-1.17,1.33-1.75,2-.76,.88-1.48,1.79-2.22,2.69-1.46,1.82-2.87,3.69-4.22,5.54s-2.63,3.73-3.83,5.57c-2.41,3.65-4.51,7.18-6.21,10.26-.53,.94-1,1.83-1.46,2.68h62.27c31.27-.1,56.56-25.47,56.56-56.74v-8.69l-28.47,.03Z"
            />
            <g>
              <path
                className="h"
                fill="currentColor"
                d="M246.13,83.54l.62-.72-12.63-12.03-.69,.76c-6.5,7.13-14.64,10.75-24.21,10.75-18.75,0-32.35-13.38-32.35-31.82s13.61-31.82,32.35-31.82c9.53,0,17.68,3.57,24.21,10.62l.69,.74,12.63-11.88-.62-.73c-8.8-10.29-22.19-15.96-37.71-15.96-29.57,0-51.03,20.62-51.03,49.03s21.4,49.03,50.89,49.03c15.6,0,29.05-5.67,37.85-15.96Z"
              />
              <path
                className="h"
                fill="currentColor"
                d="M303.67,1.44c-14.26,0-27.29,4.95-36.69,13.94-9.42,8.99-14.6,21.46-14.6,35.09s5.19,26.1,14.6,35.09c9.41,8.99,22.44,13.93,36.69,13.93,29.72,0,51.29-20.62,51.29-49.03S333.4,1.44,303.67,1.44Zm0,80.85c-18.44,0-31.82-13.38-31.82-31.82s13.38-31.82,31.82-31.82,31.82,13.38,31.82,31.82-13.38,31.82-31.82,31.82Z"
              />
              <path
                className="h"
                fill="currentColor"
                d="M451.56,36.99c0-21.11-15.62-34.22-40.75-34.22h-39.42V98.16h19.34v-27.21h20.08c1.02,0,2.06,0,3.12-.08l19.04,27.3h21.12l-21.86-31.23c12.48-5.33,19.34-15.91,19.34-29.94Zm-60.83-17.54h19.28c14.44,0,22.08,6.07,22.08,17.54s-7.84,17.68-22.08,17.68h-19.28V19.45Z"
              />
              <path
                className="h"
                fill="currentColor"
                d="M508.32,2.77h-39.42V98.16h19.34v-26.95h20.08c25.14,0,40.76-13.11,40.76-34.22s-15.62-34.22-40.76-34.22Zm-20.08,16.67h19.28c14.45,0,22.08,6.07,22.08,17.54s-7.63,17.54-22.08,17.54h-19.28V19.45Z"
              />
              <path
                className="h"
                fill="currentColor"
                d="M595.71,83.09c-10.74,0-22.59-3.95-30.19-10.05l-1.01-.81-6.83,15.34,.56,.49c8.07,6.95,22.78,11.45,37.47,11.45,12.21,0,22.28-2.94,29.11-8.5,6.22-5.05,9.64-12.25,9.64-20.25,0-20.25-18.87-24.74-34.04-28.35-11.44-2.72-21.32-5.07-21.32-12.87s7.03-11.67,19.28-11.67c8.37,0,17.34,2.56,25.24,7.2l1,.59,6.21-15.31-.69-.45c-8.06-5.28-19.88-8.44-31.63-8.44s-22.26,2.94-29.05,8.52c-6.17,5.06-9.57,12.29-9.57,20.37,0,20.47,18.91,24.98,34.1,28.6,11.48,2.74,21.39,5.1,21.39,13.02,0,7.18-6.99,11.14-19.68,11.14Z"
              />
              <path
                className="h"
                fill="currentColor"
                d="M175.68,126.42c1.65-.87,2.69-2.43,2.69-4.46,0-3.16-2.61-5.27-7.67-5.27h-9.9v20.27h10.48c5.33,0,8.11-2.03,8.11-5.53,0-2.55-1.45-4.29-3.71-5.01Zm-10.22-6.2h4.66c2.29,0,3.53,.78,3.53,2.37s-1.25,2.4-3.53,2.4h-4.66v-4.78Zm5.47,13.21h-5.47v-5.01h5.47c2.43,0,3.74,.81,3.74,2.52s-1.3,2.49-3.74,2.49Z"
              />
              <path
                className="h"
                fill="currentColor"
                d="M204.87,124.04c0-4.55-3.39-7.36-8.8-7.36h-8.78v20.27h4.69v-5.65h4.31l3.91,5.65h5.04l-4.55-6.52c2.64-1.13,4.17-3.36,4.17-6.4Zm-9.06,3.53h-3.82v-7.07h3.82c2.87,0,4.31,1.3,4.31,3.53s-1.45,3.53-4.31,3.53Z"
              />
              <polygon
                className="h"
                fill="currentColor"
                points="217.58 128.5 227 128.5 227 124.85 217.58 124.85 217.58 120.45 228.24 120.45 228.24 116.69 212.92 116.69 212.92 136.96 228.62 136.96 228.62 133.2 217.58 133.2 217.58 128.5"
              />
              <path
                className="h"
                fill="currentColor"
                d="M243.07,116.69l-9.04,20.27h4.81l1.8-4.34h9.41l1.8,4.34h4.92l-9.06-20.27h-4.63Zm-.93,12.37l3.21-7.76,3.21,7.76h-6.43Z"
              />
              <polygon
                className="h"
                fill="currentColor"
                points="282.08 116.69 276.87 116.69 267.89 126.16 267.89 116.69 263.22 116.69 263.22 136.96 267.89 136.96 267.89 131.83 270.61 129 277.1 136.96 282.57 136.96 273.68 125.72 282.08 116.69"
              />
              <rect
                className="h"
                fill="currentColor"
                x="289"
                y="116.69"
                width="4.69"
                height="20.27"
              />
              <polygon
                className="h"
                fill="currentColor"
                points="316.8 129 306.72 116.69 302.84 116.69 302.84 136.96 307.48 136.96 307.48 124.65 317.58 136.96 321.43 136.96 321.43 116.69 316.8 116.69 316.8 129"
              />
              <path
                className="h"
                fill="currentColor"
                d="M340.69,120.34c2.06,0,3.76,.72,5.24,2.29l3.01-2.78c-1.97-2.29-4.92-3.5-8.49-3.5-6.46,0-11.15,4.37-11.15,10.48s4.69,10.48,11.06,10.48c2.9,0,5.97-.9,8.2-2.58v-8.22h-4.29v5.94c-1.19,.64-2.38,.87-3.65,.87-3.88,0-6.57-2.69-6.57-6.49s2.69-6.49,6.63-6.49Z"
              />
              <polygon
                className="h"
                fill="currentColor"
                points="383.76 129 373.68 116.69 369.8 116.69 369.8 136.96 374.43 136.96 374.43 124.65 384.54 136.96 388.39 136.96 388.39 116.69 383.76 116.69 383.76 129"
              />
              <polygon
                className="h"
                fill="currentColor"
                points="402.81 128.5 412.22 128.5 412.22 124.85 402.81 124.85 402.81 120.45 413.46 120.45 413.46 116.69 398.14 116.69 398.14 136.96 413.84 136.96 413.84 133.2 402.81 133.2 402.81 128.5"
              />
              <polygon
                className="h"
                fill="currentColor"
                points="443.12 131.05 438.48 116.69 434.14 116.69 429.36 130.94 424.78 116.69 419.92 116.69 426.58 136.96 431.59 136.96 436.19 123.23 440.65 136.96 445.69 136.96 452.33 116.69 447.84 116.69 443.12 131.05"
              />
              <path
                className="h"
                fill="currentColor"
                d="M482.35,120.34c2.06,0,3.77,.72,5.24,2.29l3.01-2.78c-1.97-2.29-4.92-3.5-8.49-3.5-6.46,0-11.15,4.37-11.15,10.48s4.69,10.48,11.06,10.48c2.9,0,5.97-.9,8.2-2.58v-8.22h-4.29v5.94c-1.19,.64-2.38,.87-3.65,.87-3.88,0-6.57-2.69-6.57-6.49s2.69-6.49,6.63-6.49Z"
              />
              <path
                className="h"
                fill="currentColor"
                d="M516.5,124.04c0-4.55-3.39-7.36-8.8-7.36h-8.78v20.27h4.69v-5.65h4.32l3.91,5.65h5.04l-4.55-6.52c2.63-1.13,4.17-3.36,4.17-6.4Zm-9.06,3.53h-3.82v-7.07h3.82c2.87,0,4.31,1.3,4.31,3.53s-1.45,3.53-4.31,3.53Z"
              />
              <path
                className="h"
                fill="currentColor"
                d="M534.37,116.34c-6.4,0-11.09,4.46-11.09,10.48s4.69,10.48,11.09,10.48,11.09-4.43,11.09-10.48-4.72-10.48-11.09-10.48Zm0,16.97c-3.62,0-6.34-2.63-6.34-6.49s2.72-6.49,6.34-6.49,6.34,2.64,6.34,6.49-2.72,6.49-6.34,6.49Z"
              />
              <path
                className="h"
                fill="currentColor"
                d="M566.89,127.87c0,3.85-1.65,5.44-4.52,5.44s-4.52-1.59-4.52-5.44v-11.18h-4.69v11.35c0,5.97,3.42,9.27,9.18,9.27s9.18-3.3,9.18-9.27v-11.35h-4.63v11.18Z"
              />
              <polygon
                className="h"
                fill="currentColor"
                points="594.46 129 584.38 116.69 580.5 116.69 580.5 136.96 585.13 136.96 585.13 124.65 595.24 136.96 599.09 136.96 599.09 116.69 594.46 116.69 594.46 129"
              />
              <path
                className="h"
                fill="currentColor"
                d="M617.45,116.69h-9.21v20.27h9.21c6.63,0,11.18-4,11.18-10.14s-4.55-10.14-11.18-10.14Zm-.23,16.42h-4.29v-12.57h4.29c4.03,0,6.66,2.4,6.66,6.28s-2.64,6.29-6.66,6.29Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </GatsbyLink>
  );
};
