import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import "./Footer.scss";
import { Logo } from "../../../theme/Logo";
import GatsbyLink from "../GatsbyLink";
import { SocialLinks } from "../SocialLinks";
import { decodeEntities } from "../../utils/helpers";

const FooterComponent = ({
  data: {
    siteSettings,
    wordpressWpSettings,
    legalNavigation: { items: legalNav },
    footerNavigation,
  },
  location,
}) => {
  const [isHomePage, setHomePage] = useState(false);

  useEffect(() => {
    location.pathname === "/" ? setHomePage(true) : setHomePage(false);
  }, [location]);

  const { navigationInFooter, locations } = siteSettings.options;
  const { items: footerNav } = footerNavigation;

  return (
    <footer className="footer">
      <div className="wrap">
        <div className="inner">
          <div className="left-side">
            <Logo />
            <div className="contact-method">
              <address className="method">{locations[0].addressStreet}</address>
              <address className="method">{locations[0].addressCity}</address>
            </div>
            <div className="contact-method">
              <address className="method">
                <a
                  href={`tel:${decodeEntities(locations[0].phone)}`}
                  className="contact-email underline"
                >
                  {decodeEntities(locations[0].phone)}
                </a>
              </address>
            </div>
          </div>

          <div className="right-side">
            <div className="footer-navigation">
              {navigationInFooter && (
                <ul>
                  {Object.keys(footerNav).length > 0 &&
                    footerNav?.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={`footer-navigation__item ${
                            item.classes !== ""
                              ? `footer-navigation__item--${item.classes}`
                              : ""
                          }`}
                        >
                          <GatsbyLink
                            to={item.url}
                            aria-label={item.title}
                            decode={true}
                          >
                            {item.title}
                          </GatsbyLink>

                          {item.children && (
                            <ul>
                              {Object.keys(item.children).length > 0 &&
                                item.children?.map((child, child_index) => {
                                  return (
                                    <li
                                      key={`child-${child_index}`}
                                      className={`footer-navigation__item-child ${
                                        item.classes !== ""
                                          ? `footer-navigation__item--${child.classes}`
                                          : ""
                                      }`}
                                    >
                                      <GatsbyLink
                                        to={child.url}
                                        aria-label={child.title}
                                        decode={true}
                                      >
                                        {child.title}
                                      </GatsbyLink>
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className="social-wrapper">
          <SocialLinks />
        </div>
        <div className="legal-wrapper">
          <ul className="legal-navigation">
            <li>
              ©{new Date().getFullYear()} - {wordpressWpSettings.title}. All
              rights reserved.
            </li>

            {Object.keys(legalNav).length > 0 &&
              legalNav?.map((navItem, index) => {
                return (
                  <li key={index}>
                    <GatsbyLink to={navItem.url} decode={true}>
                      {navItem.title}
                    </GatsbyLink>
                  </li>
                );
              })}
          </ul>
          <ul className="legal-navigation">
            {isHomePage ? (
              <li className="credit">
                Website by{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://distl.com.au/"
                >
                  Distl
                </a>
              </li>
            ) : (
              <li className="credit">Website by Distl</li>
            )}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          legalNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "legal-navigation" }
          ) {
            items {
              title
              url
              classes
              slug: object_slug
            }
          }
          footerNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "footer-navigation" }
          ) {
            items {
              title
              url
              classes
              slug: object_slug
              children: wordpress_children {
                title
                url
                classes
                slug: object_slug
              }
            }
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              socialLinksHeader
              locations {
                # addressDisplay
                email
                phone
                addressStreet
                addressCity
              }
              navigationInFooter
            }
          }
          wordpressWpSettings {
            title
          }
        }
      `}
      render={(data) => <FooterComponent data={data} {...props} />}
    />
  );
}
